import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

function shouldWriteLogs() {
    return process.env.MODE !== 'dev';
}

//Allow XHR HappyNest only and all the not XHR errors
const isHappyNestXHRError = (message: string): boolean =>
    !message.includes('XHR') || message.includes('happynest');

export const initDatadogLogClient = () => {
    if (shouldWriteLogs()) {
        datadogLogs.setLoggerGlobalContext({
            host: window.location.hostname,
        });

        let datadogConfig: LogsInitConfiguration = {
            clientToken: process.env.DATADOG_KEY as string,
            site: 'datadoghq.com',
            service: 'HN-REACT-APP',
            forwardErrorsToLogs: true,
            sampleRate: 100,
            beforeSend: (log) => {
                return isHappyNestXHRError(log.message);
            },
        };

        if (process.env.VERSION) {
            datadogConfig = { ...datadogConfig, version: process.env.VERSION };
        }

        datadogLogs.init(datadogConfig);
    }
};
