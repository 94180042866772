import Shadows from '~constants/shadows';

const style: Style = {
    dropdown: {
        '.MuiList-root': {
            p: 0,
        },

        '.MuiPaper-root': {
            width: '100%',
            maxWidth: 300,
            pt: 0,
            boxShadow: Shadows.large,
            borderRadius: '16px',
        },
    },
};

export default style;
