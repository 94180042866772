import { Box, IconButton, Menu } from '@mui/material';
import { useState } from 'react';
import Avatar from '~shared/avatar';
import UserMenuWrapper from '../user-menu-wrapper';
import style from './user-menu-desktop.style';

const UserMenuDesktop = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box>
                <IconButton id='avatar' onClick={handleClick}>
                    <Avatar size={40} />
                </IconButton>
            </Box>

            <Menu sx={style.dropdown} anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Box pb={3}>
                    <UserMenuWrapper onDialogOpen={handleClose} />
                </Box>
            </Menu>
        </>
    );
};

export default UserMenuDesktop;
