import { Box, Stack } from '@mui/material';
import RemainingCredits from '~components/remaining-credits';
import { UserMenuDesktop } from '~components/user-menu';
import useAppSelector from '~hooks/use-app-selector';
import Logo from '~shared/logo';
import { getUserLoggedIn } from '~store/modules/auth/selectors';
import Logout from '../logout';

interface IHeaderDesktop {
    showLogoOnly?: boolean;
}

const HeaderDesktop: React.FC<IHeaderDesktop> = ({ showLogoOnly = false }) => {
    const isUserLoggedIn = useAppSelector(getUserLoggedIn);

    return (
        <Stack
            width='100%'
            direction='row'
            gap={3}
            alignItems='center'
            justifyContent='end'
            data-testid='desktop-header'
        >
            <Box marginRight='auto'>
                <Logo />
            </Box>

            {!showLogoOnly && (
                <>
                    <RemainingCredits />
                    <UserMenuDesktop />
                </>
            )}

            {showLogoOnly && isUserLoggedIn && <Logout />}
        </Stack>
    );
};

export default HeaderDesktop;
