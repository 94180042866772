import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    addBillingError,
    avsError,
    CardValidationResult,
    getCardReprocessErrorText,
    getCardValidationErrorText,
    IBillingForm,
    IBillingInfo,
    IBillingInfoState,
    IBillingValidationState,
    ICardForm,
    ICardValidationResult,
    ICardValidationResultState,
    IReprocessDeclinedCardInfo,
    IReprocessDeclinedCardInfoState,
    IReprocessState,
    IUserBillingInfoForm,
    ReprocessCardResult,
    tooManyAttempts,
} from '~constants/billing-info';
import { ToastIds } from '~constants/toast-ids';
import {
    isAVSError,
    isBadRequestError,
    isNotFoundError,
    isTooManyRequests,
    isUnauthorizedError,
} from '~utils/errors';
import { IRequestState, ITokenRequest } from '~utils/hn-api';
import { defaultErrorMessage, showApiErrorToast, showErrorToast } from '~utils/toast-utils';
import { getAccountInfoAsync, getBillingValidationStatusAsync } from '../account-info';
import {
    billingHistoryRequest,
    billingInfoRequest,
    IBillingHistory,
    invoicePdfRequest,
    missedPickupsInvoicePdfRequest,
    reprocessDeclinedInvoicesByLinkRequest,
    reprocessDeclinedInvoicesInfoByLinkRequest,
    reprocessDeclinedInvoicesInfoRequest,
    reprocessDeclinedInvoicesRequest,
    reprocessFailedPreAuthsByLinkRequest,
    reprocessFailedPreAuthsInfoByLinkRequest,
    reprocessFailedPreAuthsInfoRequest,
    reprocessFailedPreAuthsRequest,
    reprocessSettleUpDeclinedInvoicesRequest,
    updateBillingInfoRequest,
    validateCardRequest,
} from './api';
import { resetPromoCodeValidation, validatePromoCodeAsync } from '../promo-codes';

const showUnverifiedCardToast = (validationResult: CardValidationResult) => {
    if (validationResult !== CardValidationResult.Verified) {
        showErrorToast(
            getCardValidationErrorText(validationResult),
            ToastIds.INVALID_CREDIT_CARD + validationResult.toString(),
        );
    }
};

// TODO: implement error case
const initialState = {
    history: new Array<IBillingHistory>(),
    historyLoading: false,

    invoiceDownloadListLoading: {} as {
        [id: string]: boolean;
    },

    info: {
        data: {
            billingAddress: '',
            billingAptSuite: '',
            billingCity: '',
            billingEmail: '',
            billingFirstName: '',
            billingLastName: '',
            billingPhone: '',
            billingState: '',
            billingZipCode: '',
            cardNumber: '',
            expDate: '',
        } as IBillingInfo,
        isLoading: false,
        isSucceed: false,
    } as IBillingInfoState,

    updateInfo: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,

    cardValidation: {
        data: {
            isAmericanExpress: true,
            validationResult: CardValidationResult.Verified,
        } as ICardValidationResult,
        isLoading: false,
        isSucceed: false,
    } as ICardValidationResultState,

    reprocessDeclinedInvoicesInfo: {
        data: {
            firstName: '',
            subscriberPhone: '',
            subscriberEmail: '',
            amount: 0,
        } as IReprocessDeclinedCardInfo,
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IReprocessDeclinedCardInfoState,

    reprocessFailedPreAuthsInfo: {
        data: {
            firstName: '',
            subscriberPhone: '',
            subscriberEmail: '',
            amount: 0,
        } as IReprocessDeclinedCardInfo,
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IReprocessDeclinedCardInfoState,

    reprocessDeclinedInvoices: {
        isLoading: false,
        isSucceed: false,
        isError: false,
        isRepeatRequest: false,
    } as IReprocessState,

    reprocessFailedPreAuths: {
        isLoading: false,
        isSucceed: false,
        isError: false,
        isRepeatRequest: false,
    } as IReprocessState,

    billingValidation: {
        card: CardValidationResult.Verified,
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IBillingValidationState,
};

export const fetchBillingHistory = createAsyncThunk('billing/history', async () => {
    const { data } = await billingHistoryRequest();
    return data;
});

export const getInvoicePdf = createAsyncThunk('billing/invoice', async (invoiceId: number) => {
    await invoicePdfRequest(invoiceId);
});

export const getMissedPickupsInvoicePdf = createAsyncThunk(
    'billing/missedPickups',
    async (invoiceId: number) => {
        await missedPickupsInvoicePdfRequest(invoiceId);
    },
);

export const fetchBillingInfo = createAsyncThunk('billing/fetchInfo', async () => {
    const { data } = await billingInfoRequest();
    return data;
});

export const validateCard = createAsyncThunk(
    '/signup/validateCard',
    async (cardInfo: ICardForm) => {
        const { data } = await validateCardRequest(cardInfo);
        showUnverifiedCardToast(data.validationResult);
        return data;
    },
);

export const validateBilling = createAsyncThunk(
    '/signup/validateBilling',
    async (cardInfo: IBillingForm, { dispatch }) => {
        const validationResult = {
            card: CardValidationResult.Verified,
        } as IBillingValidationState;

        dispatch(resetPromoCodeValidation());
        if (cardInfo.promoCode) {
            dispatch(validatePromoCodeAsync(cardInfo.promoCode));
        }
        const { data: cardValidationResult } = await validateCardRequest(cardInfo);
        showUnverifiedCardToast(cardValidationResult.validationResult);
        validationResult.card = cardValidationResult.validationResult;

        return validationResult;
    },
);

export const fetchReprocessDeclinedInvoicesInfo = createAsyncThunk(
    'billing/reprocessDeclinedInvoicesInfo',
    async (includeMissedPickups?: boolean) => {
        const { data } = await reprocessDeclinedInvoicesInfoRequest(includeMissedPickups);

        return data;
    },
);

export const fetchReprocessDeclinedInvoicesInfoByLink = createAsyncThunk(
    'billing/reprocessDeclinedInvoicesInfoByLink',
    async ({ token, onInvalidToken }: ITokenRequest) => {
        try {
            const { data } = await reprocessDeclinedInvoicesInfoByLinkRequest(token);

            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const reprocessDeclinedInvoices = createAsyncThunk(
    'billing/reprocessDeclinedInvoices',
    async (includeMissedPickups: boolean, { dispatch, getState }: any) => {
        const { isRepeatRequest } = getState().billing.reprocessDeclinedInvoices;

        try {
            const { data } = await reprocessDeclinedInvoicesRequest(includeMissedPickups);
            if (data.status !== ReprocessCardResult.Reprocessed) {
                showErrorToast(
                    getCardReprocessErrorText(data.status),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else if (data.amount !== 0) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            }

            dispatch(getAccountInfoAsync());
            return data;
        } catch (error: any) {
            if (isRepeatRequest) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else {
                throw error;
            }
        }
    },
);

export const reprocessDeclinedInvoicesByLink = createAsyncThunk(
    'billing/reprocessDeclinedInvoicesByLink',
    async ({ token, onInvalidToken }: ITokenRequest, { getState }: any) => {
        const { isRepeatRequest } = getState().billing.reprocessDeclinedInvoices;

        try {
            const { data } = await reprocessDeclinedInvoicesByLinkRequest(token);
            if (data.status !== ReprocessCardResult.Reprocessed) {
                showErrorToast(
                    getCardReprocessErrorText(data.status),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else if (data.amount !== 0) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            }

            return data;
        } catch (error: any) {
            if (isUnauthorizedError(error)) {
                onInvalidToken();
            } else if (isRepeatRequest) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else {
                throw error;
            }
        }
    },
);

export const reprocessSettleUpDeclinedInvoices = createAsyncThunk(
    'billing/reprocessSettleUpDeclinedInvoices',
    async ({ token, onInvalidToken }: ITokenRequest, { getState }: any) => {
        const { isRepeatRequest } = getState().billing.reprocessDeclinedInvoices;

        try {
            const { data } = await reprocessSettleUpDeclinedInvoicesRequest(token);
            if (data.status !== ReprocessCardResult.Reprocessed) {
                showErrorToast(
                    getCardReprocessErrorText(data.status),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else if (data.amount !== 0) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            }

            return data;
        } catch (error: any) {
            if (isUnauthorizedError(error)) {
                onInvalidToken();
            } else if (isRepeatRequest) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else {
                throw error;
            }
        }
    },
);

export const fetchReprocessFailedPreAuthsInfo = createAsyncThunk(
    'billing/reprocessFailedPreAuthsInfo',
    async () => {
        const { data } = await reprocessFailedPreAuthsInfoRequest();

        return data;
    },
);

export const fetchReprocessFailedPreAuthsInfoByLink = createAsyncThunk(
    'billing/reprocessFailedPreAuthsInfoByLink',
    async ({ token, onInvalidToken }: ITokenRequest) => {
        try {
            const { data } = await reprocessFailedPreAuthsInfoByLinkRequest(token);

            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const reprocessFailedPreAuths = createAsyncThunk(
    'billing/reprocessFailedPreAuths',
    async (_, { dispatch, getState }: any) => {
        const { isRepeatRequest } = getState().billing.reprocessFailedPreAuths;

        try {
            const { data } = await reprocessFailedPreAuthsRequest();
            if (data.status !== ReprocessCardResult.Reprocessed) {
                showErrorToast(
                    getCardReprocessErrorText(data.status),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else if (data.amount !== 0) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            }

            dispatch(getAccountInfoAsync());
            return data;
        } catch (error: any) {
            if (isRepeatRequest) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else {
                throw error;
            }
        }
    },
);

export const reprocessFailedPreAuthsByLink = createAsyncThunk(
    'billing/reprocessFailedPreAuthsByLink',
    async ({ token, onInvalidToken }: ITokenRequest, { getState }: any) => {
        const { isRepeatRequest } = getState().billing.reprocessFailedPreAuths;

        try {
            const { data } = await reprocessFailedPreAuthsByLinkRequest(token);
            if (data.status !== ReprocessCardResult.Reprocessed) {
                showErrorToast(
                    getCardReprocessErrorText(data.status),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else if (data.amount !== 0) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            }

            return data;
        } catch (error: any) {
            if (isUnauthorizedError(error)) {
                onInvalidToken();
            } else if (isRepeatRequest) {
                showErrorToast(
                    getCardReprocessErrorText(ReprocessCardResult.TransactionDeclined),
                    ToastIds.REPROCESS_CARD_REJECTED,
                );
            } else {
                throw error;
            }
        }
    },
);

export const updateBillingInfo = createAsyncThunk(
    'billing/updateInfo',
    async (args: { form: IUserBillingInfoForm; includeMissedPickups?: boolean }, { dispatch }) => {
        try {
            await updateBillingInfoRequest(args.form);

            dispatch(getBillingValidationStatusAsync());
            dispatch(fetchReprocessDeclinedInvoicesInfo(args.includeMissedPickups));
            dispatch(fetchReprocessFailedPreAuthsInfo());
            dispatch(getAccountInfoAsync());
        } catch (err: any) {
            if (isBadRequestError(err)) {
                showErrorToast(addBillingError, ToastIds.ADD_BILLING_ERROR);
            } else if (isAVSError(err)) {
                showErrorToast(avsError, ToastIds.ADD_BILLING_ERROR);
            } else if (isTooManyRequests(err)) {
                showErrorToast(tooManyAttempts, ToastIds.TOO_MANY_UPDATE_BILLING_REQUESTS);
            } else if (isNotFoundError(err)) {
                showErrorToast(defaultErrorMessage, ToastIds.UPDATE_BILLING_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.UPDATE_BILLING_REJECTED);
            }
            throw err;
        }
    },
);

const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBillingHistory.pending, (state) => {
            state.historyLoading = true;
        });
        builder.addCase(fetchBillingHistory.fulfilled, (state, action) => {
            state.history = action.payload;
            state.historyLoading = false;
            state.invoiceDownloadListLoading = {};
        });
        builder.addCase(fetchBillingHistory.rejected, (state) => {
            state.historyLoading = false;
        });

        builder.addCase(getInvoicePdf.pending, (state, action) => {
            state.invoiceDownloadListLoading[action.meta.arg] = true;
        });
        builder.addCase(getInvoicePdf.fulfilled, (state, action) => {
            state.invoiceDownloadListLoading[action.meta.arg] = false;
        });
        builder.addCase(getInvoicePdf.rejected, (state, action) => {
            state.invoiceDownloadListLoading[action.meta.arg] = false;
        });

        builder.addCase(getMissedPickupsInvoicePdf.pending, (state, action) => {
            state.invoiceDownloadListLoading[action.meta.arg] = true;
        });
        builder.addCase(getMissedPickupsInvoicePdf.fulfilled, (state, action) => {
            state.invoiceDownloadListLoading[action.meta.arg] = false;
        });
        builder.addCase(getMissedPickupsInvoicePdf.rejected, (state, action) => {
            state.invoiceDownloadListLoading[action.meta.arg] = false;
        });

        builder.addCase(updateBillingInfo.pending, (state) => {
            state.updateInfo.isLoading = true;
            state.updateInfo.isSucceed = false;
        });
        builder.addCase(updateBillingInfo.fulfilled, (state) => {
            state.updateInfo.isLoading = false;
            state.updateInfo.isSucceed = true;
        });
        builder.addCase(updateBillingInfo.rejected, (state) => {
            state.updateInfo.isLoading = false;
            state.updateInfo.isSucceed = false;
        });

        builder.addCase(fetchBillingInfo.pending, (state) => {
            state.info.isLoading = true;
            state.info.isSucceed = false;
        });
        builder.addCase(fetchBillingInfo.fulfilled, (state, action) => {
            state.info.isLoading = false;
            state.info.isSucceed = true;
            state.info.data = action.payload;
        });
        builder.addCase(fetchBillingInfo.rejected, (state) => {
            state.info.isLoading = false;
            state.info.isSucceed = false;
        });

        builder.addCase(validateCard.pending, (state) => {
            state.cardValidation.isLoading = true;
            state.cardValidation.isSucceed = false;
        });
        builder.addCase(validateCard.fulfilled, (state, action) => {
            state.cardValidation.isLoading = false;
            state.cardValidation.isSucceed = true;
            state.cardValidation.data = action.payload;
        });
        builder.addCase(validateCard.rejected, (state) => {
            state.cardValidation.isLoading = false;
            state.cardValidation.isSucceed = false;
        });

        builder.addCase(fetchReprocessDeclinedInvoicesInfo.pending, (state) => {
            state.reprocessDeclinedInvoicesInfo.isLoading = true;
            state.reprocessDeclinedInvoicesInfo.isSucceed = false;
        });
        builder.addCase(fetchReprocessDeclinedInvoicesInfo.fulfilled, (state, action) => {
            state.reprocessDeclinedInvoicesInfo.isLoading = false;
            state.reprocessDeclinedInvoicesInfo.isSucceed = true;
            state.reprocessDeclinedInvoicesInfo.data = action.payload;
        });
        builder.addCase(fetchReprocessDeclinedInvoicesInfo.rejected, (state) => {
            state.reprocessDeclinedInvoicesInfo.isLoading = false;
            state.reprocessDeclinedInvoicesInfo.isSucceed = false;
        });

        builder.addCase(fetchReprocessDeclinedInvoicesInfoByLink.pending, (state) => {
            state.reprocessDeclinedInvoicesInfo.isLoading = true;
            state.reprocessDeclinedInvoicesInfo.isSucceed = false;
        });
        builder.addCase(fetchReprocessDeclinedInvoicesInfoByLink.fulfilled, (state, action) => {
            state.reprocessDeclinedInvoicesInfo.isLoading = false;
            state.reprocessDeclinedInvoicesInfo.isSucceed = true;
            if (action.payload !== undefined) {
                state.reprocessDeclinedInvoicesInfo.data = action.payload;
            }
        });
        builder.addCase(fetchReprocessDeclinedInvoicesInfoByLink.rejected, (state) => {
            state.reprocessDeclinedInvoicesInfo.isLoading = false;
            state.reprocessDeclinedInvoicesInfo.isSucceed = false;
        });

        builder.addCase(reprocessDeclinedInvoices.pending, (state) => {
            state.reprocessDeclinedInvoices.isLoading = true;
            state.reprocessDeclinedInvoices.isSucceed = false;
            state.reprocessDeclinedInvoices.isError = false;
        });
        builder.addCase(reprocessDeclinedInvoices.fulfilled, (state, action) => {
            state.reprocessDeclinedInvoices.isLoading = false;
            state.reprocessDeclinedInvoices.isSucceed = true;
            state.reprocessDeclinedInvoices.isError = false;
            state.reprocessDeclinedInvoices.isRepeatRequest = true;

            if (action.payload !== undefined) {
                state.reprocessDeclinedInvoicesInfo.data.amount = action.payload.amount;
            }
        });
        builder.addCase(reprocessDeclinedInvoices.rejected, (state) => {
            state.reprocessDeclinedInvoices.isLoading = false;
            state.reprocessDeclinedInvoices.isSucceed = false;
            state.reprocessDeclinedInvoices.isError = false;
            state.reprocessDeclinedInvoices.isRepeatRequest = true;
        });

        builder.addCase(reprocessDeclinedInvoicesByLink.pending, (state) => {
            state.reprocessDeclinedInvoices.isLoading = true;
            state.reprocessDeclinedInvoices.isSucceed = false;
            state.reprocessDeclinedInvoices.isError = false;
        });
        builder.addCase(reprocessDeclinedInvoicesByLink.fulfilled, (state, action) => {
            state.reprocessDeclinedInvoices.isLoading = false;
            state.reprocessDeclinedInvoices.isSucceed = true;
            state.reprocessDeclinedInvoices.isError = false;
            state.reprocessDeclinedInvoices.isRepeatRequest = true;

            if (action.payload !== undefined) {
                state.reprocessDeclinedInvoicesInfo.data.amount = action.payload.amount;
            }
        });
        builder.addCase(reprocessDeclinedInvoicesByLink.rejected, (state) => {
            state.reprocessDeclinedInvoices.isLoading = false;
            state.reprocessDeclinedInvoices.isSucceed = false;
            state.reprocessDeclinedInvoices.isError = true;
            state.reprocessDeclinedInvoices.isRepeatRequest = true;
        });

        builder.addCase(reprocessSettleUpDeclinedInvoices.pending, (state) => {
            state.reprocessDeclinedInvoices.isLoading = true;
            state.reprocessDeclinedInvoices.isSucceed = false;
            state.reprocessDeclinedInvoices.isError = false;
        });
        builder.addCase(reprocessSettleUpDeclinedInvoices.fulfilled, (state, action) => {
            state.reprocessDeclinedInvoices.isLoading = false;
            state.reprocessDeclinedInvoices.isSucceed = true;
            state.reprocessDeclinedInvoices.isError = false;
            state.reprocessDeclinedInvoices.isRepeatRequest = true;

            if (action.payload !== undefined) {
                state.reprocessDeclinedInvoicesInfo.data.amount = action.payload.amount;
            }
        });
        builder.addCase(reprocessSettleUpDeclinedInvoices.rejected, (state) => {
            state.reprocessDeclinedInvoices.isLoading = false;
            state.reprocessDeclinedInvoices.isSucceed = false;
            state.reprocessDeclinedInvoices.isError = true;
            state.reprocessDeclinedInvoices.isRepeatRequest = true;
        });

        builder.addCase(fetchReprocessFailedPreAuthsInfo.pending, (state) => {
            state.reprocessFailedPreAuthsInfo.isLoading = true;
            state.reprocessFailedPreAuthsInfo.isSucceed = false;
        });
        builder.addCase(fetchReprocessFailedPreAuthsInfo.fulfilled, (state, action) => {
            state.reprocessFailedPreAuthsInfo.isLoading = false;
            state.reprocessFailedPreAuthsInfo.isSucceed = true;
            state.reprocessFailedPreAuthsInfo.data = action.payload;
        });
        builder.addCase(fetchReprocessFailedPreAuthsInfo.rejected, (state) => {
            state.reprocessFailedPreAuthsInfo.isLoading = false;
            state.reprocessFailedPreAuthsInfo.isSucceed = false;
        });

        builder.addCase(fetchReprocessFailedPreAuthsInfoByLink.pending, (state) => {
            state.reprocessFailedPreAuthsInfo.isLoading = true;
            state.reprocessFailedPreAuthsInfo.isSucceed = false;
        });
        builder.addCase(fetchReprocessFailedPreAuthsInfoByLink.fulfilled, (state, action) => {
            state.reprocessFailedPreAuthsInfo.isLoading = false;
            state.reprocessFailedPreAuthsInfo.isSucceed = true;
            if (action.payload !== undefined) {
                state.reprocessFailedPreAuthsInfo.data = action.payload;
            }
        });
        builder.addCase(fetchReprocessFailedPreAuthsInfoByLink.rejected, (state) => {
            state.reprocessFailedPreAuthsInfo.isLoading = false;
            state.reprocessFailedPreAuthsInfo.isSucceed = false;
        });

        builder.addCase(reprocessFailedPreAuths.pending, (state) => {
            state.reprocessFailedPreAuths.isLoading = true;
            state.reprocessFailedPreAuths.isSucceed = false;
            state.reprocessFailedPreAuths.isError = false;
        });
        builder.addCase(reprocessFailedPreAuths.fulfilled, (state, action) => {
            state.reprocessFailedPreAuths.isLoading = false;
            state.reprocessFailedPreAuths.isSucceed = true;
            state.reprocessFailedPreAuths.isError = false;
            state.reprocessFailedPreAuths.isRepeatRequest = true;

            if (action.payload !== undefined) {
                state.reprocessFailedPreAuthsInfo.data.amount = action.payload.amount;
            }
        });
        builder.addCase(reprocessFailedPreAuths.rejected, (state) => {
            state.reprocessFailedPreAuths.isLoading = false;
            state.reprocessFailedPreAuths.isSucceed = false;
            state.reprocessFailedPreAuths.isError = true;
            state.reprocessFailedPreAuths.isRepeatRequest = true;
        });

        builder.addCase(reprocessFailedPreAuthsByLink.pending, (state) => {
            state.reprocessFailedPreAuths.isLoading = true;
            state.reprocessFailedPreAuths.isSucceed = false;
            state.reprocessFailedPreAuths.isError = false;
        });
        builder.addCase(reprocessFailedPreAuthsByLink.fulfilled, (state, action) => {
            state.reprocessFailedPreAuths.isLoading = false;
            state.reprocessFailedPreAuths.isSucceed = true;
            state.reprocessFailedPreAuths.isError = false;
            state.reprocessFailedPreAuths.isRepeatRequest = true;

            if (action.payload !== undefined) {
                state.reprocessFailedPreAuthsInfo.data.amount = action.payload.amount;
            }
        });
        builder.addCase(reprocessFailedPreAuthsByLink.rejected, (state) => {
            state.reprocessFailedPreAuths.isLoading = false;
            state.reprocessFailedPreAuths.isSucceed = false;
            state.reprocessFailedPreAuths.isError = true;
            state.reprocessFailedPreAuths.isRepeatRequest = true;
        });

        builder.addCase(validateBilling.pending, (state) => {
            state.billingValidation.isLoading = true;
            state.billingValidation.isSucceed = false;
            state.billingValidation.isError = false;
        });
        builder.addCase(validateBilling.fulfilled, (state, action) => {
            state.billingValidation.isLoading = false;
            state.billingValidation.isSucceed = true;
            state.billingValidation.isError = false;

            const { card } = action.payload;

            state.billingValidation.card = card;
        });
        builder.addCase(validateBilling.rejected, (state) => {
            state.billingValidation.isLoading = false;
            state.billingValidation.isSucceed = false;
            state.billingValidation.isError = true;
        });
    },
});

export default billingSlice.reducer;
